<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="relative bg-blueGray-200 pt-8 pb-6">
  <div
    class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-200 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap text-center lg:text-left">
      <div class="w-full lg:w-6/12 px-4">
        <h4 class="text-3xl font-semibold">Sign up for the Owlry Wait List to get news, updates, and early access to the Beta.</h4>
        <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
        </h5>
        <div class="mt-6 lg:mb-0 mb-6">
    
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="flex flex-wrap items-top mb-6">
          <div class="w-full lg:w-4/12 px-4 ml-auto">
            <span
              class="block uppercase text-blueGray-500 text-sm font-semibold mb-2"
            >
              Useful Links
            </span>
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                  href="https://www.owlry.io/?ref=ns-footer"
                >
                  About Us
                </a>
              </li>
             
            </ul>
          </div>
          <div class="w-full lg:w-4/12 px-4">
           
            
          </div>
        </div>
      </div>
    </div>
    <hr class="my-6 border-blueGray-300" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-1">
          Copyright © {date} Owlry / ABPA Labs Inc.
          <a
            href="https://www.owlry.io"
            class="text-blueGray-500 hover:text-blueGray-800"
          >
            Owlry
          </a>
          .
        </div>
      </div>
    </div>
  </div>
</footer>
